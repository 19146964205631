import React, { useEffect, useState } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./views/Home.js";
import ScanPage from "./views/ScanPage.js";
import {Place, annonceLoader} from "./views/Place.js";
import SignUp from "./views/login/SignUp.js";
import SignIn from "./views/login/SignIn.js";
import RuledPage from "./utils/RuledPage.js";
import ControlledPopup from "./views/popup.js";
import Root from "./views/Root.js";
import TimerScanpage from "./views/TimerScanpage.js";
import Auth from "./views/dashboard/Auth.js";
import AddEvent from "./views/dashboard/event/AddEvent.js";
import Dashboard from "./views/dashboard/Dashboard.js";
import AccessControlRoute from "./utils/AccessControlRoute.js";
import Maintenance from "./views/Maintenance.js";
import AddAnncs from "./views/dashboard/annonce/AddAnncs.js";

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route errorElement={<Home />} path="/" element={<Root />}>
        <Route path="/" element={<Home />} />

        <Route path="/scanpage" exact element={<ScanPage />} />

        <Route path="/giftpage" exact element={<Place />} />

        {/* <Route path="/:codeevent" exact element={<Place />} /> */}

        {/* <Route element={<AccessControlRoute />}> */}
          <Route element={<RuledPage />}>
            <Route path="/:codeevent/*" element={<Place />}  />
          </Route>
        {/* </Route> */}


        <Route path="/timer" exact element={<TimerScanpage />} />

        {/* <Route path="/:codeevent" exact element={<Maintenance />} /> */}


        <Route path="/signup" exact element={<SignUp />} />

        <Route path="/signin" exact element={<SignIn />} />

        <Route element={<Auth />}> 
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/dashboard/annonce/add" exact element={<AddAnncs />} />
          <Route path="/dashboard/event/add" exact element={<AddEvent />} />

        </Route> 
      </Route>
    )
  );

  return <RouterProvider router={router} />;
};

export default App;
