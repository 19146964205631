import React, {useEffect, useState} from 'react';
import { useNavigate, useParams, Navigate, Outlet } from 'react-router-dom';
import Timer from '../components/Timer';
import useCookie from '../hooks/Cookie';

const TimedRoute = () => {

  const navigate = useNavigate()

  const [timer, setTimer] = useState(undefined);
  const stopr = 'okau'

 // verifier si variable dans localstorage 
 // how get localstorage variable ? 


  useEffect(() => {
   
    if ( useCookie.ifCookie('pauser') ) {
      const t = useCookie.getCookie('pauser')
      const val = JSON.parse(t)

      const ndate = new Date(val.expiry)
      console.log(ndate)
      setTimer(ndate)
    }
  }, [stopr]);

    return (
        timer == undefined  ? <Outlet /> : <Timer expiryTimestamp={timer} postExpire={ ()=> navigate('/scanpage') } />  
    );
};

export default TimedRoute;