import { WinnerUrl } from "../hooks/Env";


const destPage = (annonce_id, ref) => {

    const winnerMessage = `https://wa.me/${process.env.REACT_APP_CONTACT_NUMBER}?text=Hello%2C%20%0Aje%20suis%20le%20gagnant%20de%20l%27annonce%20${annonce_id}%0AVoici%20mon%20num%C3%A9ro%20gagnant%20${ref}%0A%0AComment%20pourrais-je%20entrer%20en%20possession%20de%20mon%20cadeau%3F%20`

    
    return winnerMessage ;
}
 
export default destPage;