import client from './Client';
import UniqueId from './UniqueId';

// import axios from 'axios';
// import client from './Client';

const SetStat = async (code_event=null, id_anncs=null) => {
   
     const res = UniqueId.setUniqueId()
    // console.log(res.data);

    // var IP = res.data.IPv4
   console.log(res)
    await client
    .post("/visite", {
      code_event : code_event,
      id_anncs : id_anncs,
      url : window.location.pathname,
      ip : res
    })
    .then((res) => {
      return res.data.messageJson
    })
    .catch((err) => {
      return "Erreur de l'enregistrement de la visite"
    } )
  }

export default SetStat;