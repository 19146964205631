

//how write an editable table

import { NavLink } from "react-router-dom";



const Dashboard = () => {
    return ( 
        <>
         <h1>
            Bienvenue sur votre dashboard
        </h1>
            <nav>
                <ul>
                    <li> <NavLink to="/dashboard/annonce/ads"> Annonces </NavLink> </li>
                    <li> <NavLink to="/dashboard/event/event"> Evenements </NavLink> </li>
                </ul>
            </nav>
        </>
       
     );
}
 
export default Dashboard;