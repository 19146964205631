import React from "react";
import { useForm } from "react-hook-form";
import client from "../../../hooks/Client";


// Fonction pour envoyer les données au serveur
const sendData = (data) => {
    try {

      const formData = new FormData();
      for ( var key in data ) {
       formData.append(key, data[key]);
     }

      //recuperer l'id de l'annonceur
      
      // Remplacer l'URL par celle du serveur
      //const response = await client.post(`/annonceur/${id}/event/${data.idEvent}/annonce`, {
        const response = client.post(`/annonce`, {
        method: "POST",
        body: formData,
      });
      // Vérifier le statut de la réponse
      if (response.ok) {
        // Afficher un message de succès
        alert("Données envoyées avec succès");
      } else {
        // Afficher un message d'erreur
        alert("Erreur lors de l'envoi des données");
      }
    } catch (error) {
      // Afficher un message d'erreur
      alert("Erreur lors de l'envoi des données");
    }
  };

// Composant réutilisable pour les champs de texte
const TextField = ({ label, name, register, required, helpText="" }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <input
      type="text"
      id={name}
      name={name}
      {...register(name, { required })}
      className="form-control"
    />
    <small id="helpId" className="form-text text-muted">
        {helpText }
      </small>
  </div>
);

// Composant réutilisable pour les champs de nombre
const NumberField = ({ label, name, register, required, helpText="" }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <input
      type="number"
      id={name}
      name={name}
      {...register(name, { required })}
      className="form-control"
    />
    <small id="helpId" className="form-text text-muted">
        {helpText }
      </small>
  </div>
);

// Composant réutilisable pour les listes déroulantes
const SelectField = ({ label, name, register, required, options, helpText="" }) => (
  
  <div className="form-group">
    <label htmlFor={name}>{label}</label> 
    <select
      id={name}
      name={name}
      {...register(name, { required })}
      className="form-control form-select-lg"
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
    <small id="helpId" className="form-text text-muted">
        {helpText }
      </small>
  </div>
);

// Composant réutilisable pour le champ de fichier
const FileField = ({ label, name, register, required, helpText="" }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <input
      type="file"
      id={name}
      name={name}
      {...register(name, { required })}
      className="form-control"
    />
    <small id="helpId" className="form-text text-muted">
        {helpText}
      </small>
  </div>
);


// Composant principal du formulaire
const AddAnncs = () => {
  
  // decoder le token pour récupérer les données
  // const { decodedToken } = useJwt(localStorage.getItem('sweet'));
  const decodedToken = 'okay'
  const { handleSubmit, register, formState: { errors } } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    data.id_anncrs = "1"
    data.type_med = "image"
    data.type_url = "site web"
    sendData(data)
  };
  
  return (
    <div className="container-fluid p-5" >
        
      <h2 className="d-flex justify-content-center text-center">Ajouter une annonce</h2>
      <div className="d-flex justify-content-center ">
        <div className="col-md-6">
    <form onSubmit={handleSubmit(onSubmit)}>

    <SelectField
        label="Campagne"
        name="code_event"
        register={register}
        required="Ce champ est obligatoire"
        options={[
          { value: "", label: "Sélectionnez une option" },
          { value: "uac", label: "UAC" },
          { value: "american-dream", label: "American Dream" },
        ]}
      />

    <SelectField
        label="Type d'annonce"
        name="type_anncs"
        register={register}
        required="Ce champ est obligatoire"
        options={[
          { value: "", label: "Sélectionnez une option" },
          { value: "1", label: "Gagnante" },
          { value: "2", label: "Publicitaire" },
        ]}
      />

    <SelectField
        label="Objectif de l'annonce"
        name="objectif"
        register={register}
        required="Ce champ est obligatoire"
        options={[
          { value: "", label: "Sélectionnez une option" },
          { value: "Message WhatsApp", label: "Message Whatsapp" },
          { value: "Visite site web", label: "Visite web" },
          { value: "Abonnement", label: "Abonnement" },

        ]}
      />

      <TextField
        label="Destination"
        name="url_des"
        placeholder="https://monsite.com"
        helpText="Lien de site ou lien WhatsApp"
        register={register}
        required="Ce champ est obligatoire"
      />
      

      <NumberField
        label="Limite"
        name="limite"
        register={register}
        helpText="Nombre de fois que l'annonce s'affiche"
        required="Ce champ est obligatoire"
      />
      <NumberField
        label="Duree en jours"
        name="duree"
        register={register}
        helpText="Nombre de fois que l'annonce s'affiche"
        required="Ce champ est obligatoire"
      />
       <TextField
        label="Description/Message"
        name="description"
        placeholder="Message "
        register={register}
        helpText="Court texte d'appel à l'action"
        
      />
       <FileField
        label="Affiche de l'annonce"
        name="joinedFile"
        register={register}
        helpText="L'image qui sera affiché à l'utilisateur"
        required="Ce champ est obligatoire"
      />
     
      <button type="submit" className="btn btn-primary mr-3">
        Envoyer
      </button>
      <button type="reset" className="btn btn-danger">
        Annuler
      </button>
    </form>
    </div>
    </div>
    </div>
    
    
  );
};

export default AddAnncs;