import React, {useEffect, useState} from 'react';
import { Navigate, useNavigate, useParams, Outlet } from 'react-router';
import Timer from '../components/Timer';
import useCookie from '../hooks/Cookie';

const TimerScanpage = () => {


  const time = new Date();
  time.setSeconds(time.getSeconds() + 5); // 10 minutes timer

  const navigate = useNavigate()
  const [timer, setTimer] = useState();
  var ndate = new Date()
 
  useEffect(() => {
    if ( useCookie.ifCookie('pauser') ) {
      const t = useCookie.getCookie('pauser')
      const val = JSON.parse(t)

      ndate = new Date(val.expiry)
      console.log(val.expiry)
      setTimer(ndate)
    }
    alert(time)
  }, []);

    return (
    <Timer expiryTimestamp={timer} postExpire={ ()=> navigate('/scanpage') } />    
    );
};

export default TimerScanpage;