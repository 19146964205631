import React from 'react'



const AddEvent = () => {
    return ( 
        <h1>Add event</h1>
     );
}
 
export default AddEvent;