import React from 'react';

const Footer = () => {

    return (
            <ul className="footer-list d-flex justify-content-center text-center pl-0 mt-3">
                <a className='style-none text-danger' href="https://wa.me/+22940538164" target="_blank" rel="noopener noreferrer"><li className="list-group-item ml-2">  <i className="fa fa-whatsapp" aria-hidden="true"></i> </li></a> 
                 <a className='style-none' href="https://instagram.com/scan.gift" target="_blank" rel="noopener noreferrer"> <li className="list-group-item"><i className="fa fa-instagram" aria-hidden="true"></i></li></a>
                <a className='style-none text-danger' href="mailto:contact@wescan.gift" target="_blank" rel="noopener noreferrer"> <li className="list-group-item mr-2"> <i className="fa fa-envelope" aria-hidden="true"></i></li></a>  
            </ul>
    );
};

export default Footer;