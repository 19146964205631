import React, { useEffect, useState } from "react";
import client from "../hooks/Client.js";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import SliderAdd from "../components/SliderAdd";
import ButtonPrimary from "../components/ButtonPrimary";
import useCookie from "../hooks/Cookie.js";
import { WinnerUrl } from "../hooks/Env";
import SetStat from "../hooks/SetStat.js";
import destPage from "../utils/destPage.js";

export const Place = () => {
  const [annonce, setAnnonce] = useState({});
  const [isWinner, setIsWinner] = useState(false);
  const [ref, setRef] = useState();
  // decrémentation de limite
  const [image, setImage] = useState(true);

  const [loading, setLoading] = useState(false);

  var els = [];
  const [medias, setMedias] = useState();

  const [addEl, setAddEl] = useState();
  //var addEl  // element de l'annonce

  const [destination, setDestination] = useState();
  


  function setDestUrl() {
    // if (annonce !== {} && annonce != null ) {
    const url = WinnerUrl + ref;
    isWinner ? setDestination(url) : setDestination(annonce.url_des);
    // }
    console.log(destination, ref);
  }

  useEffect(() => {
    setLoading(true);

    if (document.readyState === "complete") {
      setLoading(false);
    }
  }, [loading]);

  window.onload = (event) => {
    setLoading(false);
    console.log("La page est complètement chargée");
  };

  window.onbeforeunload = (event) => {
    const e = event || window.event;
    // Cancel the event
    e.preventDefault();
    if (e) {
      e.returnValue = "Votre avancement sera abandonné"; // Legacy method for cross browser support
    }
    return ""; // Legacy method for cross browser support
  };

  const onDestClick = () => {
    console.log(destination)
    client.post("gagner", {
      idAnncs: annonce.id_anncs,
      CodeEvent: annonce.code_event,
      ref: ref,
    });
    client.post("/visite", {
        code_event : annonce.code_event,
        id_anncs : annonce.id_anncs,
        url : window.location.pathname,
        ip : 'clique'
    })
  
    window.open(destPage(annonce.id_anncs, ref), "_blank", "noopener noreferrer");
    // window.location.replace(destination)
  };


  const [msgToPlace, setMsgToPlace] = useState("");
  const { codeevent } = useParams();



  // useEffect(() => {
  //   //requete de l'annonce
  //   setAnnonce(preannonce)
  // }, []);


    const qrString = codeevent ? `/select/aleatoire/annonce/${codeevent}` : `/select/aleatoire/annonce/invalide`;

  
    useEffect(() => {
      (async function () {
  
        if (
          !useCookie.ifCookie("annonce") ||
          useCookie.getCookie("annonce") == ""
        ) {
          client
            .get(qrString)
            .then((res) => {
              const data = res.data.data;
  
              setAnnonce(JSON.parse(JSON.stringify(data)));
              console.log("ann :" + JSON.stringify(data), codeevent);
  
            })
            .catch((err) => {
              console.error(err);
              return err;
            });
        } else {
          client
            .get(qrString)
            .then((res) => {
              const data = res.data.data;
  
              setAnnonce(JSON.parse(JSON.stringify(data)));
              console.log("ann :" + JSON.stringify(data));
            })
            .catch((err) => {
              console.error(err);
            });
        }
  
      })();
    }, []);
  
  useEffect(() => {
    (async function () {
      // est-ce une annonce gagnante ?
      if (annonce.type_anncs === 1) {
        // initalisation de la réf s'il gagne | generation de ref

        //préciser que c'est une annonce gagnante
        setIsWinner(true);

        await client
          .get("generateur/ref")
          .then((res) => {
            // const d = res.data.data.ref;
            setRef(res.data.data.ref);
           
            useCookie.setCookie("pauser", true, 30);
          })
          .catch((err) => console.error(err));


      }

      setDestination(annonce.url_des);

    })();
  }, [annonce]);

  // format d'affichage de l'annonce
  useEffect(() => {
    (async function () {

      setMedias(annonce.media);
      
      // set the add type
        setAddEl(
          <img
            src={process.env.REACT_APP_API_BASE_URL + "/" + medias[0].url_med}
            alt="annonce"
            className="scangif p-0 img-fluid"
            onClick={onDestClick}
          />
        );

        SetStat(codeevent, annonce.id_anncs);

      // }, 5000);
    })();
  }, [ref, annonce.medias, medias]);

   

  return (
    <div className="text-center">
      {loading ? (
        <ClipLoader loading={loading} size={80} />
      ) : (
        <div className="card bg-transparent text-center border-0">
          <div className="card-header bg-transparent border-0">
            <h3 className="title-s-1 text-danger my-3 change-color">Clique sur l'image !</h3>
          </div>

          <div className="mb-2 d-flex flex-column justify-content-center align-items-center">
            <div className=" text-center">
              {addEl}
            </div>
          </div>

          <div className="text-center bg-transparent mt-4 border-0">
            {/* <p> {annonce.description}</p> */}
          </div>

          {
            isWinner ? <ButtonPrimary toUrl="#" style={{ background : '#FF004D' }} text="Recevoir mon cadeau" action={onDestClick} /> : <ButtonPrimary toUrl="/scanpage" text="Scanne à nouveau" />
          }
          
        </div>
      )}
    </div>
  );
};



export const annonceLoader = async() => {

  
  // const annonce = useAnncs(codeevent)
  const pathname = window.location.pathname
  const id_anncs = pathname.split('/').pop()
 
  
  const qrString = id_anncs ? `/select/aleatoire/annonce/${id_anncs}` : `/select/aleatoire/annonce/default`;

  client.get(qrString)
          .then((res) => {
            console.log(res.data)
            return res.data.data 
            })
          .catch((err) => {
            console.error(err);
            return err;
          });
          
}
 