import React, { useState, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import SignIn from '../login/SignIn';

const Auth = () => {

    const auth = localStorage.getItem('number')
    // const auth = false

    return auth ? <Outlet /> : <Navigate to='/signin' /> ;
}
 
export default Auth;