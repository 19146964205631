import React from 'react';
import { Outlet } from 'react-router';
import Sidebar from '../components/Sidebar';
import Footer from '../components/Footer';

const Root = () => {
    
    return (
        <>
         <Sidebar />
         <div>
            <Outlet />
         </div>
         <Footer />
        </>
    );
};

export default Root;