import client from "../Client";
import { useNavigate } from "react-router-dom";

const Login = (number, password) => {

    // client.post("/annonceur/login", {
    //     method: "POST",
    //     body: JSON.stringify({
    //         number,
    //         password,
    //     }),
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    // })
    //     .then((res) => res.json())
    //     .then((resp) => {
    //         if (!resp.data.sucess) {
    //             alert(resp.data.msg);
    //         } else {
    //             //👇🏻 Logs the username to the console
    //             console.log(resp.data.data);
    //             //👇🏻 save the number to the local storage
    //             localStorage.setItem("token", resp.data.data.token);
    //             localStorage.setItem("number", resp.data.data.number);
    //             localStorage.setItem("number", resp.data.data.username);
    //             //👇🏻 Navigates to the dashboard route
    //             navigate("/dashboard");
    //         }
    //     })
    //     .catch((err) => console.error(err));

    if (number == "40538164" && password == "WSG@2023") {
         console.log({ number, password });
        localStorage.setItem("number", number);
       window.open('/dashboard', '_self')
    } else {
        alert('Verifiez le numéro et le mot de passe.')
    }
    

        return null
};

export default Login