import { v4 as uuidv4 } from 'uuid';

const UniqueId =(function() {

    const setUniqueId = function () {
        if ( localStorage.getItem('unique-id') !== null && localStorage.getItem('unique-id') !== "" ) {
            return localStorage.getItem('unique-id')       
        }
        else {
            const uniqueId = uuidv4(); // ⇨ '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d'
            localStorage.setItem('unique-id', uniqueId.toString() );
            return localStorage.getItem('unique-id')  
        }
        
    }

    const checkUniqueId = function(){
       
        if ( localStorage.getItem('unique-id') !== null && localStorage.getItem('unique-id') !== "" ) {
            return true
        } else {
            return false
        }
    }

    const getUniqueId = function(){
        return localStorage.getItem('unique-id');
    }

    return {
        setUniqueId,
        checkUniqueId,
        getUniqueId,
    }
}
)()
 
export default UniqueId;